import { bySport } from "../../common";

const defaultColleges = bySport<Record<string, number>>({
	baseball: {},
	basketball: {
		"Abilene Christian": 0.1,
		"Air Force": 1,
		Akron: 4,
		Alabama: 28,
		"Alabama A&M": 1,
		"Alabama State": 2,
		Albany: 5,
		"Alcorn State": 6,
		"American University": 2,
		"Appalachian State": 1,
		Arizona: 57,
		"Arizona State": 29,
		Arkansas: 33,
		"Arkansas State": 3,
		"Arkansas-Little Rock": 7,
		"Arkansas-Pine Bluff": 1,
		Army: 0.1,
		Auburn: 23,
		"Austin Peay": 7,
		"Ball State": 3,
		Baylor: 19,
		Belmont: 1,
		"Bethune-Cookman": 3,
		Binghamton: 0.1,
		"Boise State": 6,
		"Boston College": 27,
		"Boston University": 6,
		"Bowling Green": 16,
		Bradley: 21,
		Brown: 2,
		"Bryant University": 0.1,
		Bucknell: 1,
		Buffalo: 1,
		Butler: 6,
		BYU: 22,
		"Cal Poly": 1,
		"Cal State Bakersfield": 1,
		"Cal State Fullerton": 12,
		"Cal State Northridge": 1,
		California: 37,
		"California Baptist": 0.1,
		Campbell: 2,
		Canisius: 11,
		"Central Arkansas": 1,
		"Central Connecticut State": 2,
		"Central Michigan": 8,
		Charleston: 3,
		"Charleston Southern": 0.1,
		Charlotte: 8,
		Chattanooga: 4,
		"Chicago State": 0.1,
		Cincinnati: 37,
		Citadel: 0.1,
		Clemson: 20,
		"Cleveland State": 6,
		"Coastal Carolina": 0.1,
		Colgate: 4,
		Colorado: 25,
		"Colorado State": 11,
		Columbia: 5,
		Connecticut: 37,
		"Coppin State": 2,
		Cornell: 3,
		Creighton: 16,
		Dartmouth: 6,
		Davidson: 6,
		Dayton: 22,
		Delaware: 0.1,
		"Delaware State": 1,
		Denver: 6,
		DePaul: 37,
		"Detroit Mercy": 22,
		Drake: 14,
		Drexel: 3,
		Duke: 86,
		Duquesne: 22,
		"East Carolina": 3,
		"East Tennessee State": 3,
		"Eastern Illinois": 2,
		"Eastern Kentucky": 7,
		"Eastern Michigan": 11,
		"Eastern Washington": 2,
		Elon: 1,
		Evansville: 5,
		Fairfield: 2,
		"Fairleigh Dickinson": 0.1,
		Florida: 33,
		"Florida A&M": 4,
		"Florida Atlantic": 0.1,
		"Florida Gulf Coast": 1,
		"Florida International": 2,
		"Florida State": 38,
		Fordham: 11,
		"Fresno State": 23,
		Furman: 3,
		"Gardner-Webb": 4,
		"George Mason": 3,
		"George Washington": 13,
		Georgetown: 45,
		Georgia: 22,
		"Georgia Southern": 3,
		"Georgia State": 2,
		"Georgia Tech": 39,
		Gonzaga: 20,
		"Grambling State": 12,
		"Grand Canyon": 2,
		"Green Bay": 4,
		Hampton: 2,
		Hartford: 1,
		Harvard: 4,
		Hawaii: 8,
		"High Point": 1,
		Hofstra: 5,
		"Holy Cross": 11,
		Houston: 34,
		"Houston Baptist": 1,
		Howard: 2,
		Idaho: 4,
		"Idaho State": 6,
		Illinois: 44,
		"Illinois State": 5,
		"Illinois-Chicago": 2,
		"Incarnate Word": 0.1,
		Indiana: 68,
		"Indiana State": 10,
		Iona: 6,
		Iowa: 32,
		"Iowa State": 33,
		IUPUI: 1,
		"Jackson State": 14,
		Jacksonville: 11,
		"Jacksonville State": 1,
		"James Madison": 2,
		Kansas: 76,
		"Kansas City": 1,
		"Kansas State": 26,
		"Kennesaw State": 0.1,
		"Kent State": 3,
		Kentucky: 116,
		"La Salle": 24,
		Lafayette: 0.1,
		Lamar: 5,
		Lehigh: 1,
		Liberty: 3,
		Lipscomb: 1,
		LIU: 23,
		"Long Beach State": 20,
		Longwood: 1,
		"Louisiana Tech": 8,
		"Louisiana-Lafayette": 10,
		"Louisiana-Monroe": 6,
		Louisville: 60,
		"Loyola (MD)": 2,
		"Loyola Chicago": 16,
		"Loyola Marymount": 9,
		LSU: 45,
		Maine: 2,
		Manhattan: 11,
		Marist: 1,
		Marquette: 40,
		Marshall: 13,
		Maryland: 43,
		"Maryland-Eastern Shore": 4,
		Massachusetts: 8,
		"Massachusetts-Lowell": 0.1,
		"McNeese State": 6,
		Memphis: 37,
		Mercer: 1,
		Merrimack: 0.1,
		"Miami (FL)": 16,
		"Miami (OH)": 0.1,
		Michigan: 57,
		"Michigan State": 49,
		"Middle Tennessee": 2,
		Milwaukee: 0.1,
		Minnesota: 49,
		"Mississippi State": 18,
		"Mississippi Valley State": 1,
		Missouri: 32,
		"Missouri State": 5,
		Monmouth: 1,
		Montana: 5,
		"Montana State": 1,
		"Morehead State": 6,
		"Morgan State": 1,
		"Mount St. Mary's": 2,
		"Murray State": 12,
		"N.J.I.T.": 0.1,
		Navy: 2,
		Nebraska: 13,
		"Nebraska-Omaha": 0.1,
		Nevada: 12,
		"New Hampshire": 0.1,
		"New Mexico": 22,
		"New Mexico State": 16,
		"New Orleans": 8,
		Niagara: 11,
		"Nicholls State": 2,
		"Norfolk State": 5,
		"North Alabama": 0.1,
		"North Carolina": 97,
		"North Carolina A&T": 5,
		"North Carolina Central": 3,
		"North Carolina State": 48,
		"North Carolina-Wilmington": 2,
		"North Dakota": 2,
		"North Dakota State": 0.1,
		"North Florida": 0.1,
		"North Texas": 5,
		Northeastern: 5,
		"Northern Arizona": 3,
		"Northern Colorado": 1,
		"Northern Illinois": 8,
		"Northern Iowa": 0.1,
		"Northern Kentucky": 0.1,
		Northwestern: 18,
		"Northwestern State": 2,
		"Notre Dame": 59,
		Oakland: 4,
		Ohio: 10,
		"Ohio State": 47,
		Oklahoma: 25,
		"Oklahoma State": 28,
		"Old Dominion": 9,
		"Ole Miss": 9,
		"Oral Roberts": 9,
		Oregon: 31,
		"Oregon State": 29,
		Pacific: 8,
		"Penn State": 14,
		Pennsylvania: 11,
		Pepperdine: 19,
		Pittsburgh: 22,
		Portland: 8,
		"Portland State": 2,
		"Prairie View A&M": 2,
		Presbyterian: 0.1,
		Princeton: 9,
		Providence: 30,
		Purdue: 38,
		"Purdue Fort Wayne": 1,
		Quinnipiac: 0.1,
		Radford: 1,
		"Rhode Island": 17,
		Rice: 12,
		Richmond: 3,
		Rider: 2,
		"Robert Morris": 1,
		Rutgers: 13,
		"Sacramento State": 0.1,
		"Sacred Heart": 0.1,
		"Saint Joseph's (PA)": 21,
		"Saint Louis": 15,
		"Saint Mary's": 7,
		"Sam Houston State": 2,
		Samford: 0.1,
		"San Diego": 1,
		"San Diego State": 12,
		"San Francisco": 25,
		"San Jose State": 9,
		"Santa Clara": 14,
		Seattle: 11,
		"Seton Hall": 28,
		Siena: 1,
		"SIU-Edwardsville": 0.1,
		SMU: 17,
		"South Alabama": 5,
		"South Carolina": 26,
		"South Carolina State": 3,
		"South Dakota": 1,
		"South Dakota State": 3,
		"South Florida": 6,
		"Southeast Missouri State": 2,
		"Southeastern Louisiana": 0.1,
		"Southern Illinois": 10,
		"Southern Miss": 5,
		"Southern University": 6,
		"Southern Utah": 0.1,
		"St. Bonaventure": 17,
		"St. Francis (BKN)": 1,
		"St. Francis (PA)": 5,
		"St. John's": 54,
		"St. Peter's": 4,
		Stanford: 32,
		"Stephen F. Austin": 2,
		Stetson: 2,
		"Stony Brook": 1,
		Syracuse: 53,
		TCU: 10,
		Temple: 35,
		Tennessee: 39,
		"Tennessee State": 19,
		"Tennessee Tech": 4,
		"Tennessee-Martin": 1,
		Texas: 34,
		"Texas A&M": 17,
		"Texas A&M-CC": 0.1,
		"Texas Rio Grande Valley": 0.1,
		"Texas Southern": 5,
		"Texas State": 2,
		"Texas Tech": 14,
		"Texas-Arlington": 1,
		Toledo: 11,
		Towson: 3,
		Troy: 0.1,
		Tulane: 14,
		Tulsa: 18,
		UAB: 10,
		"UC Davis": 0.1,
		"UC Irvine": 8,
		"UC Riverside": 1,
		"UC Santa Barbara": 9,
		UCF: 8,
		UCLA: 99,
		UMBC: 0.1,
		"UNC Asheville": 0.1,
		"UNC Greensboro": 0.1,
		UNLV: 41,
		USC: 44,
		"USC Upstate": 2,
		Utah: 31,
		"Utah State": 9,
		"Utah Valley": 3,
		UTEP: 17,
		UTSA: 2,
		Valparaiso: 7,
		Vanderbilt: 27,
		Vermont: 0.1,
		Villanova: 49,
		Virginia: 31,
		"Virginia Commonwealth": 10,
		"Virginia Military": 2,
		"Virginia Tech": 16,
		Wagner: 0.1,
		"Wake Forest": 31,
		Washington: 39,
		"Washington State": 17,
		"Weber State": 10,
		"West Virginia": 16,
		"Western Carolina": 7,
		"Western Illinois": 1,
		"Western Kentucky": 33,
		"Western Michigan": 7,
		"Wichita State": 19,
		"William & Mary": 2,
		Winthrop: 0.1,
		Wisconsin: 27,
		Wofford: 1,
		"Wright State": 2,
		Wyoming: 25,
		Xavier: 20,
		Yale: 4,
		"Youngstown State": 0.1,
	},
	football: {
		Clemson: 249,
		Louisville: 170,
		"Wake Forest": 136,
		"Boston College": 238,
		"Florida State": 313,
		Syracuse: 257,
		"North Carolina State": 190,
		Virginia: 189,
		"Virginia Tech": 165,
		Pittsburgh: 332,
		"North Carolina": 246,
		"Miami (FL)": 369,
		Duke: 131,
		"Georgia Tech": 193,
		Memphis: 122,
		Navy: 32,
		SMU: 159,
		Tulane: 121,
		Houston: 172,
		Tulsa: 167,
		Cincinnati: 149,
		UCF: 80,
		Temple: 129,
		"South Florida": 56,
		"East Carolina": 86,
		Connecticut: 59,
		Oklahoma: 374,
		Baylor: 226,
		"Kansas State": 185,
		"Oklahoma State": 202,
		Texas: 335,
		"Iowa State": 120,
		TCU: 193,
		"West Virginia": 220,
		"Texas Tech": 154,
		Kansas: 164,
		"Ohio State": 481,
		"Penn State": 397,
		Michigan: 395,
		Indiana: 212,
		"Michigan State": 322,
		Maryland: 230,
		Rutgers: 125,
		Minnesota: 258,
		Wisconsin: 340,
		Iowa: 293,
		Illinois: 292,
		Nebraska: 374,
		Purdue: 287,
		Northwestern: 196,
		"Florida Atlantic": 27,
		"Western Kentucky": 49,
		Marshall: 81,
		Charlotte: 3,
		"Florida International": 17,
		"Middle Tennessee": 31,
		"Old Dominion": 10,
		"Louisiana Tech": 86,
		UAB: 22,
		"Southern Miss": 128,
		"North Texas": 73,
		UTSA: 9,
		Rice: 86,
		UTEP: 94,
		"Notre Dame": 582,
		Liberty: 20,
		"Brigham Young": 170,
		Army: 33,
		"New Mexico State": 55,
		Massachusetts: 42,
		"Miami (OH)": 80,
		Buffalo: 32,
		"Kent State": 55,
		Ohio: 49,
		"Bowling Green": 57,
		Akron: 39,
		"Central Michigan": 48,
		"Western Michigan": 64,
		"Ball State": 41,
		"Northern Illinois": 72,
		Toledo: 86,
		"Eastern Michigan": 45,
		"Boise State": 89,
		"Air Force": 19,
		"Utah State": 135,
		Wyoming: 91,
		"Colorado State": 133,
		"New Mexico": 72,
		"San Diego State": 210,
		Hawaii: 95,
		Nevada: 82,
		"San Jose State": 134,
		"Fresno State": 144,
		UNLV: 82,
		Oregon: 252,
		California: 267,
		Washington: 308,
		"Oregon State": 178,
		"Washington State": 194,
		Stanford: 279,
		Utah: 192,
		USC: 524,
		"Arizona State": 287,
		UCLA: 340,
		Colorado: 244,
		Arizona: 192,
		LSU: 368,
		Alabama: 371,
		Auburn: 301,
		"Texas A&M": 320,
		"Mississippi State": 202,
		"Ole Miss": 248,
		Arkansas: 259,
		Georgia: 339,
		Florida: 354,
		Tennessee: 348,
		Kentucky: 180,
		Missouri: 212,
		"South Carolina": 199,
		Vanderbilt: 118,
		"Appalachian State": 52,
		"Georgia Southern": 21,
		"Georgia State": 10,
		Troy: 60,
		"Coastal Carolina": 11,
		"Louisiana-Lafayette": 28,
		"Arkansas State": 61,
		"Louisiana-Monroe": 49,
		"Texas State": 42,
		"South Alabama": 2,
		"South Central Louisiana State University": 1,
	},
	hockey: {},
});

export default defaultColleges;
